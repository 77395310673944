<template>
  <!-- 本页搜索栏 -->
  <div class="tradHeader">
    <div class="tradContent">
      <p class="tradWord01">国内商标45类搜索</p>
      <div class="tradPut">
        <div class="tradBar">
          <input
            class="tradInput01"
            type="text"
            placeholder="请输入商品名称/服务名称"
            v-model="inputText"
          />
        </div>
        <div class="tradAnniu" @click="fn2()" @keyup.enter="trango">
          <p class="traw01">搜</p>
          <p>索</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["text"],
  data() {
    return {
      inputText: this.text,
    };
  },
  watch: {
    text(newData, oldData) {
      if (newData) {
        this.inputText = newData;
      } else {
        this.inputText = "";
      }
    },
  },
  methods: {
    fn2() {
      this.$emit("inputText", this.inputText);
    },
  },
};
</script>

<style scoped lang="scss">
.tradHeader {
  width: 100%;
  height: 7.9375rem /* 127px -> 7.9375rem */;
  background-color: #4d89f1;
  display: flex;
  justify-content: center;
  .tradContent {
    width: 74.375rem /* 1190px -> 74.375rem */;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-right: 18.75rem /* 300px -> 18.75rem */;
    box-sizing: border-box;

    .tradWord01 {
      font-size: 1.5rem /* 24px -> 1.5rem */;
      color: #fff;
    }

    .tradPut {
      height: 2.6875rem /* 43px -> 2.6875rem */;
      margin-top: 0.625rem /* 10px -> 0.625rem */;
      display: flex;
      .tradBar {
        width: 26.5625rem /* 425px -> 26.5625rem */;
        height: 100%;
        background-color: #fff;
        margin-right: 1.125rem /* 18px -> 1.125rem */;
        border-radius: 0.375rem /* 6px -> 0.375rem */;
        display: flex;
        justify-content: center;
        align-items: center;
        .tradInput01 {
          border: none;
          height: 80%;
          width: 90%;
          outline: none;
          font-size: 1.25rem /* 20px -> 1.25rem */;
        }
      }
      .tradAnniu {
        width: 7.25rem /* 116px -> 7.25rem */;
        height: 100%;
        text-align: center;
        line-height: 2.6875rem /* 43px -> 2.6875rem */;
        color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        background-color: #ff7e00;
        border-radius: 0.375rem /* 6px -> 0.375rem */;
        display: flex;
        justify-content: center;
        cursor: pointer;
        .traw01 {
          margin-right: 0.625rem /* 10px -> 0.625rem */;
        }
      }
    }
  }
}
</style>