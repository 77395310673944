<template>
  <!-- 除首页外其他问标网的搜索栏 -->
  <div class="coulmnIndex">
    <div class="coulmnContent">
      <!-- logo图标 -->
      <router-link to="/">
      <img src="../imger/WBWlogo01.jpg" title="问标网" class="coulmnIcon01" alt="问标网">
      </router-link>

      <!-- 搜索栏针对 -->
      <div class="coulmnPut">
        <div class="coulmnSousuo">
          <div class="shuru">
            <input
              class="coulmnInput01"
              type="text"
              placeholder="请输入商品名/产品名/关键字"
              v-model="newtradName"
              @keyup.enter="trango"
            />
          </div>

          <div class="coulmnAnniu" @click="trango">
            <span class="coulmnWorder01">搜索</span>
            <img src="../imger/sousuo01.png" alt="商标查询网" class="coulmnIcon02" />
          </div>
        </div>
        <div class="coulmnTitle">
          <a
            class="quick"
            @click="fn1(index)"
            v-for="(item, index) in coulmnTitle"
            :key="index"
            >{{ item }}</a
          >
        </div>
      </div>

      <!--  -->
      <div class="coulmnTrak">
        <a
          href="/sblb"
          :style="{ color: show == 1 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >商标分类</a
        ><span>|</span>
        <a
          href="/zcsb"
          :style="{ color: show == 2 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >注册商标</a
        ><span>|</span>
        <a
          href="/server"
          :style="{ color: show == 3 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >服务中心</a
        ><span>|</span>
        <a
          href="/news"
          :style="{ color: show == 4 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >新闻资讯</a
        ><span>|</span>
        <a
          href="/about"
          :style="{ color: show == 5 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >关于我们</a
        ><span>|</span>
        <a
          href="/contact"
          :style="{ color: show == 6 ? '#4D89F1' : '' }"
          id="coulmnWord03"
          >联系我们</a
        >
      </div>
    </div>
  </div>
</template>

<script>
// 加密js文件引入
import { encryptDes, decryptDes,generatekey } from '@/config/DES'
export default {
  props: ["tradName", "buldword"],
  data() {
    return {
      coulmnTitle: ["服装", "广告传媒", "教育", "资本金融", "护肤", "餐饮"],
      newtradName: this.tradName,
      show: this.buldword,
    };
  },
  watch: {
    tradName(newData, oldData) {
      if (newData) {
        this.newtradName = newData;
      }
    },
    buldword(newData, oldData) {
      if (newData) {
        this.show = newData;
      }
    },
  },
  methods: {
    fn1(index) {
      this.newtradName = this.coulmnTitle[index];
    },
    trango() {
      //判断this.tradName有没有值其决定是否从主页搜索过来的
      if (this.tradName) {
        this.$emit("newtradName", this.newtradName);
      }
      if (this.newtradName) {
        var keys = generatekey();
        var sign = encryptDes(JSON.stringify({
          tradName: this.newtradName,
          type:"smark",
          page:1,
          }),keys);

        //在其他页面搜索是跳转到查询结果页面
        this.$router.push({
          path: `/rearch/${sign}.html`
        });
      } else {
        this.$message({
          type: "warning",
          message: "请输入您要查询的商标！",
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.coulmnIndex {
  width: 100%;
  height: 6.875rem /* 110px -> 6.875rem */;
  display: flex;
  justify-content: center;
  background: #fff;
  .coulmnContent {
    width: 74.375rem /* 1190px -> 74.375rem */;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .coulmnIcon01 {
      width: 8.5rem /* 136px -> 8.5rem */;
    }
    .coulmnPut {
      width: 25.1875rem /* 403px -> 25.1875rem */;
      .coulmnSousuo {
        height: 2.75rem /* 44px -> 2.75rem */;
        width: 100%;
        display: flex;
        .shuru {
          width: 19.25rem /* 308px -> 19.25rem */;
          height: 100%;
          display: flex;
          border: 0.25rem /* 4px -> 0.25rem */ solid #4d89f1;
          border-top-left-radius: 1.5625rem /* 25px -> 1.5625rem */;
          border-bottom-left-radius: 1.5625rem /* 25px -> 1.5625rem */;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          .coulmnInput01 {
            border: none;
            height: 80%;
            width: 90%;
            outline: none;
            font-size: 1rem /* 16px -> 1rem */;
          }
        }
        .coulmnAnniu {
          flex-grow: 1;
          background-color: #4d89f1;
          border-top-right-radius: 1.5625rem /* 25px -> 1.5625rem */;
          border-bottom-right-radius: 1.5625rem /* 25px -> 1.5625rem */;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          cursor: pointer;
          .coulmnWorder01 {
            font-size: 1rem /* 16px -> 1rem */;
            color: #fff;
          }
          .coulmnIcon02 {
            width: 1.125rem /* 18px -> 1.125rem */;
            height: 1.125rem /* 18px -> 1.125rem */;
          }
        }
      }
      .coulmnTitle {
        margin-top: 0.3125rem /* 5px -> 0.3125rem */;
        margin-left: 1.5625rem /* 25px -> 1.5625rem */;
        .quick {
          font-size: 0.875rem /* 14px -> 0.875rem */;
          color: #999999;
          margin-right: 0.75rem /* 12px -> 0.75rem */;
          cursor: pointer;
        }
      }
    }
    .coulmnTrak {
      float: right;
      display: flex;
      justify-content: space-between;
      width: 33.5rem /* 536px -> 33.5rem */;
      font-size: 0.875rem /* 14px -> .875rem */;
      #coulmnWord03 {
        color: #000;
        font-size: 0.875rem /* 14px -> .875rem */;
        text-decoration: none;
      }
    }
  }
}
</style>