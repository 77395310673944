import { render, staticRenderFns } from "./tradHeader.vue?vue&type=template&id=f08c79d0&scoped=true&"
import script from "./tradHeader.vue?vue&type=script&lang=js&"
export * from "./tradHeader.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./tradHeader.vue?vue&type=style&index=0&id=f08c79d0&scoped=true&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "f08c79d0",
  "4d149d3d"
  
)

export default component.exports