<template>
<!-- 联系我们页面 -->
<div class="index">
  <!-- 头部组件 -->
    <Header />
    <!-- 商标头部搜索分栏 -->
    <ColumnSearch :buldword="6"/>

    <div class="back">
      <div class="coll">
        <strong class="title01">商标转让</strong>
        <strong class="title02">商标注册</strong>
        <strong class="title03">商标起名</strong>
      </div>
      <div class="icon">
        <div class="gzh">
          <img src="../imger/gzh.png" alt="问标网联系方式" class="gzhicon">
          <p>扫一扫添加微信</p>
        </div>
        <div class="box">
          <div class="boxhead">
            <img src="../imger/contactUs/icon1.png" alt="问标网联系电话" class="icon1">
            <p>业务咨询</p>
          </div>
          <p>联系人：李先生</p>
          <p>电话：13380036087</p>
        </div>
        <div class="box">
          <div class="boxhead">
            <img src="../imger/contactUs/icon2.png" alt="问标网联系电话" class="icon1">
            <p>商务合作</p>
          </div>
          <p>联系人：潘先生</p>
          <p>电话：400-850-8819</p>
        </div>
        <div class="gzh">
          <img src="../imger/sbcx.png" alt="问标网" class="gzhicon">
          <p>扫小程序，查看好标</p>
        </div>
      </div>
    </div>

    <div class="mat">
      <div class="content">
        <div class="left">
          <Map class="map" />
        </div>
        <div class="right">
          <div class="redbox">
            <div class="lan" style="backgroundColor:#E33D51;"></div>
            <p>公司名称：广州问权知识产权服务有限公司</p>
            <p>地址：广东省广州市海珠区琶洲中洲中心南塔B座15楼</p>
          </div>
          <div class="redbox">
            <div class="lan" style="backgroundColor:#F6C556;"></div>
            <p>网址：www.iwenbiao.com</p>
          </div>
          <div class="redbox">
            <div class="lan" style="backgroundColor:#6DD082;"></div>
            <p ><span class="boxword">联系人：李先生 </span><span>手机：13380036087</span></p>
            <p>电话：400-850-8819</p>
          </div>
        </div>
      </div>
    </div>
  
  <!-- 点击弹出之后的弹出框 -->
    <Drawer />
    <!-- 固定弹窗按钮 -->
    <Eject />
    <!-- sidebar组件 -->
    <Sidebar />
    <!-- 页面关于我们脚部组件 -->
    <Footer />  
</div>
  
</template>

<script>
import Header from '../components/header.vue'
import Footer from '../components/footer.vue'
import Sidebar from '../components/sidebar.vue'
import Eject from '../components/eject.vue'
import Drawer from '../components/drawer.vue'
import ColumnSearch from '../components/columnSearch.vue'
import TradHeader from '../components/trademarkClassification/tradHeader.vue'

import Map from '../config/map.vue'

export default {
  components:{
    Sidebar,Footer,Header,Drawer,Eject,ColumnSearch,
    TradHeader, Map
  },
  data(){
    return{

    }
  }
}
</script>

<style scoped lang="scss">
.map {
  overflow: hidden;
  width:39.9375rem /* 639px -> 39.9375rem */;
  height:23.9375rem /* 383px -> 23.9375rem */;
}
.index {
  width: 100%;
  .back {
    width: 100%;
    height: 41.5rem /* 664px -> 41.5rem */;
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    .coll {
      width: 74.8125rem /* 1197px -> 74.8125rem */;
      height: 24.1875rem /* 387px -> 24.1875rem */;
      background-image: url("../imger/contactUs/coll.png");
      background-size: cover;
      position: relative;
      .title01{
        position: absolute;
        top: 16rem /* 256px -> 16rem */;
        left: 13.6875rem /* 219px -> 13.6875rem */;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #fff;
        font-weight: 500;
      }
      .title02{
        position: absolute;
        top: 16rem /* 256px -> 16rem */;
        left: 34.625rem /* 554px -> 34.625rem */;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #fff;
        font-weight: 500;
      }
      .title03{
        position: absolute;
        top: 16rem /* 256px -> 16rem */;
        left: 58.8125rem /* 941px -> 58.8125rem */;
        font-size: 1.375rem /* 22px -> 1.375rem */;
        color: #fff;
        font-weight: 500;
      }
    }
    .icon {
      width: 74.8125rem /* 1197px -> 74.8125rem */;
      height: 11.375rem /* 182px -> 11.375rem */;
      display: flex;
      justify-content: space-between;
      .gzh {
        width: 9.75rem /* 156px -> 9.75rem */;
        height: 100%;
        font-size: 1.053125rem /* 16.85px -> 1.053125rem */;
        color: #666666;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .gzhicon {
          width: 100%;
          height: 9.75rem /* 156px -> 9.75rem */;
        }
      }
      .box {
        width: 24.375rem /* 390px -> 24.375rem */;
        height: 11.375rem /* 182px -> 11.375rem */;
        padding: 1.25rem /* 20px -> 1.25rem */;
        padding-left: 3.8125rem /* 61px -> 3.8125rem */;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        background-color: #fff;
        font-size: 1rem /* 16px -> 1rem */;
        color: #666666;
        .boxhead {
          display: flex;
          font-size: 1.40375rem /* 22.46px -> 1.40375rem */;
          color: #333333;
          .icon1{
            width: 2.125rem /* 34px -> 2.125rem */;
            height: 1.8125rem /* 29px -> 1.8125rem */;
            margin-right: 0.625rem /* 10px -> 0.625rem */;
          }
        }

      }
    }
  }
  .mat {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    .content {
      width: 74.375rem /* 1190px -> 74.375rem */;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        width: 39.9375rem /* 639px -> 39.9375rem */;
        height: 23.9375rem /* 383px -> 23.9375rem */;
        .map {
          width: 100%;
          height: 100%;
        }
      }
      .right {
        height: 100%;
        width: 31.25rem /* 500px -> 31.25rem */;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        .redbox {
          font-size: 1.125rem /* 18px -> 1.125rem */;
          color: #666666;
          line-height: 2.25rem /* 36px -> 2.25rem */;
          .lan{
            width: 3.125rem /* 50px -> 3.125rem */;
            height: 0.3125rem /* 5px -> 0.3125rem */;
            margin-bottom:1.25rem /* 20px -> 1.25rem */;
          }
          .boxword {
            margin-right: 6.25rem /* 100px -> 6.25rem */;
          }
        }
      }
    }
    
  }
}
</style>